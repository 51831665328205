// vars.scss
//
// Only define SCSS variables in this file, don't declare any actual style rules.


// Fonts

$fonts-primary: HelveticaNeue, Helvetica, Arial, sans-serif;


// Breakpoints

// Copied from Bootstrap
$breakpoint-lg: 1200px;
$breakpoint-md: 992px;
$breakpoint-sm: 810px;

$height-sm: 650px;

// Colors: low number is light, high number is dark

// Lightest gray for section backgrounds that contrast against sections with white backgrounds (users list search section)
$color-gray-1: #fcfcfc;
// Even lighter gray for contrasting with $color-gray-4 (table row hover state background)
$color-gray-2: #f6f6f6;
// Very light gray for contrasting a border lightly against white
$color-gray-3: #f2f2f2;
// Light gray often used for borders (comps use #d2d2d2, but with .5px width which isn't possible in the browser, we go a lil lighter)
$color-gray-4: #e6e6e6;
// Light-ish gray, generally used for deemphasized text or links (building subtitle or logout link)
$color-gray-5: #c7c7c7;
// Medium gray, generally used for copy text or for text on a dark background (technician widget)
$color-gray-6: #9e9e9e;
// Halfway between black and white, used for common text
$color-gray-7: #7f7f7f;
// Dark-ish gray
$color-gray-8: #626262;

// Badge gray
$color-gray-badge: #bfbfbf;

$color-red: #e82c37;
$color-green: #24db96;
$color-blue: #0089ff;
$color-orange: #f5a623;
$color-black: #1c1c1c;
$color-white: #ffffff;
$color-black-transparent: rgba(0, 0, 0, .5);

$color-danger: $color-red;
$color-success: $color-blue;
$color-warning: $color-orange;

// Fonts

// Most text uses $fonts-primary, but new designs are generally using these for paragraph text
$fonts-paragraph: HelveticaNeue, Helvetica, Arial, sans-serif;


// Z-Indexes: declare any z-index values through variables so order can maintained all from one place

$z-index-base: 1;
$z-index-header: 2;
$z-index-technician-widget: 3;
$z-index-notification: 4;
$z-index-modal: 10;

// Dimensions
$toaster-height: 60px;
$table-cell-padding-vertical: 16px;
$table-cell-padding-horizontal: 16px;

$header-height: 44px;
$header-height-mobile: 64px;

$ios-safari-buffer-top-bar: 32px;
$ios-safari-buffer-bottom-bar: 44px;
$ios-safari-buffer-height: $ios-safari-buffer-top-bar + $ios-safari-buffer-bottom-bar;

$card-page-max-width: 462px;

$page-toolbar-text-color: #848484;
$page-toolbar-background-color: #fafafa;
$page-toolbar-border-color: #e3e3e3;

$vertical-padding: 20px;
$horizontal-padding: 30px;
