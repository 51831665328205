@import '../../styles/shared/settings/vars';

.page-content {
    &.top-border {
        border-top: 1px solid $color-gray-4;
    }

    &.top-spacing {
        margin-top: 30px;
    }
}