@import '../../styles/shared/settings/vars';

.cell-list {
  .group {
    margin: 0 0 $vertical-padding;

    .group-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: $vertical-padding $horizontal-padding;

      font-family: $fonts-primary;
      font-size: 12px;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      color: rgba(0, 0, 0, .5);
    }
  }

  .cell {
    display: block;
    padding: 12px 0;
    margin: 0 $horizontal-padding;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    &.cell-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      .cell-thumbnail {
        flex: 0 0 auto;
        margin-right: 12px;
      }

      .cell-text {
        flex: 1 1 auto;
        overflow: hidden;
      }

      .icon-chevron-right {
        opacity: .15;
        flex: 0 0 auto;
        margin-left: 12px;
      }
    }

    &.toggle-list {
      width: 100%;
      margin: 0;
      padding: 0 $horizontal-padding;
      border-bottom: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;

      .cell-label {
        flex: 0 0 auto;
        display: flex;
        margin-right: $horizontal-padding;
      }

      .cell-body {
        overflow: hidden;
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid rgba(0, 0, 0, .05);

        .text-1 {
          margin-bottom: 0;
        }
      }

      &:last-child {
        .cell-body {
          border-bottom: 0;
        }
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    .cell-heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      font-family: $fonts-primary;
      font-size: 16px;
      font-weight: normal;
      text-transform: uppercase;
      letter-spacing: 2.5px;
      color: rgba(0, 0, 0, .5);

      &.spaced {
        margin-bottom: $vertical-padding;
      }
    }

    .text-1 {
      font-size: 16px;
      line-height: 20px;
      color: rgba(0, 0, 0, .75);
      margin-bottom: 4px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .text-2 {
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, .25);
    }
  }
}

.latch-checkbox-wrapper {
  cursor: pointer;

  .latch-checkbox {
    pointer-events: none;
  }

  &:hover {
    &:not(.disabled) {
      .latch-checkbox-toggle {
        border-color: var(--color-blue);
      }
    }
  }

  &.disabled {
    opacity: .5;
    cursor: default;
  }
}
