// card-page.scss
//
// Responsive page layout that appears as a centered card on desktop and full screen on mobile
$card-padding: 40px;
$card-padding-mobile: 16px;

.card-page {
  width: 100%;
  min-height: 100vh;
  padding: $card-padding;

  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 100%;
    max-width: $card-page-max-width;
    min-height: 500px;
    padding: $card-padding;

    box-shadow: 0 1px 2px rgba(0, 0, 0, .5);

    position: relative;

    h1,
    h2 {
      margin-bottom: 10px;
    }

    .loading {
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(255, 255, 255, .75);
        z-index: 1;
      }
    }

    .loading-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 2;
      height: 3px;
    }

    .logo,
    .logo > img {
      display: block;
      width: 68px;
      height: 16px;
    }

    .logo {
      margin-bottom: 40px;
    }

    .card-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      margin: 36px auto 0;
      position: absolute;
      bottom: calc(0% + #{$card-padding});
      width: calc(100% -  #{$card-padding*2});
    }
  }
}

@media (max-width: $card-page-max-width) {
  .card-page {
    align-items: flex-start;

    min-width: 320px;
    padding: 0;

    .card {
      padding: $card-padding-mobile;
      box-shadow: none;

      .card-footer {
        position: absolute;
        bottom: calc(0% + #{$card-padding-mobile});
        width: calc(100% -  #{$card-padding-mobile*2});
      }
    }
  }
}
