@import '~normalize.css';
@import './shared/settings/vars';
@import './modules/card-page';
@import './modules/defaults';
@import './modules/forms';
@import './modules/buttons';
@import './modules/dropdown';
@import './modules/cell-list';
@import './modules/field-list';
@import './modules/page';
@import './modules/pills';
@import './modules/layout';
@import './mixins/nav-bar';

.no-results {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 50px 15px;
  color: $color-gray-7;
  font-size: 20px;
}
