.field-list {
    .section {
      border-bottom: 1px solid rgba(0, 0, 0, .05);
  
      &:last-child {
        border-bottom: 0;
      }
    }
  
    .cell {
      padding: $vertical-padding 0;
      margin: 0 $horizontal-padding;
      border-bottom: 1px solid rgba(0, 0, 0, .05);
  
      &:last-child {
        border-bottom: 0;
      }
  
      &.action {
        padding: $vertical-padding;
      }
  
      .cell-heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 6px;
  
        font-family: $fonts-primary;
        font-size: 12px;
        font-weight: normal;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        color: rgba(0, 0, 0, .5);
  
        &.spaced {
          margin-bottom: $vertical-padding;
        }
      }
  
      .cell-body {
        font-size: 24px;
        line-height: 36px;
        color: rgba(0, 0, 0, .5);
  
        .cell-body-text {
          font-family: $fonts-primary;
          font-weight: normal;
          font-size: 12px;
        }
  
        .cell-value {
          width: 100%;
          font-size: 24px;
          line-height: 36px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
  
          &.placeholder {
            color: rgba(0, 0, 0, .25);
          }
  
          .icon-chevron-right {
            opacity: .15;
          }
        }
  
        .form-control {
          border: 0;
          padding: 0;
          font-size: 24px;
          line-height: 36px;
          height: 36px;
  
          &::placeholder {
            color: rgba(0, 0, 0, .25);
          }
        }
      }
    }
  }
  