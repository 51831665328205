// defaults.scss
//
// Sensible defaults for standard HTML elements.

* {
    box-sizing: border-box;
  }
  
  body {
    font-family: $fonts-primary;
    font-size: 16px;
    line-height: 1.4;
    color: #000;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin: 0 0 1em;
  }
  
  h2 {
    font-size: 18px;
    font-weight: bold;
  }
  
  h3 {
    font-size: 16px;
    font-weight: bold;
  }
  
  // Standard headings within page content (e.g. "User's Doors" on user detail page)
  h4 {
    font-size: 14px;
    font-weight: bold;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1.2em;
  
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  a {
    color: #000;
    text-decoration: none;
    display: inline-block;
  }
  
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  strong {
    font-weight: bold;
  }
  
  small {
    font-size: 13px;
    font-weight: 200;
    color: $color-gray-6;
  }
  
  dl {
    dt {
      color: $color-gray-5;
      margin: 0 0 10px;
  
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  
    dd {
      margin: 0 0 25px;
    }
  }
  