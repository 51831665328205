.pillsContainer {
    display: flex;
    margin: 0 -5px;
    justify-content: left;

    .pill {
        display: flex;
        margin: 0 5px;
        font-size: 11px;
        line-height: 1;
        padding: 3px 10px;
        border-radius: 9px;

        &.black {
            background-color: $color-black;
            color: $color-white;
        }
    }
}