@import '../shared/modules/forms';

// forms.scss

.form-control {
  border-bottom: 1px solid $color-gray-4;
}

.form-group:first-child {
  .form-control {
    border-top: 1px solid $color-gray-4;
  }
}
