@import '../shared/modules/card-page';

.auth-header {
  height: $header-height;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  padding: 10px;

  .logo {
    height: calc(#{$header-height} / 2);
    margin-left: 15%;
    width: auto;
  }
}

@media (max-width: $breakpoint-sm) {
  .auth-header {
    display: none;
  }
}

.card-page {
  min-height: calc(100vh - #{$header-height});

  .card {
    min-height: calc(80vh - #{$header-height});
  }

  form {
    margin-top: 36px;

    .form-control {
      border-bottom: 1px solid $color-gray-4;

      &.has-error {
        color: #000;
        border-color: $color-danger;
      }

      &:focus {
        border-color: $color-blue;

        &.has-error {
          color: #000;
          border-color: $color-danger;
        }
      }
    }
  }

  .card-footer {
    button {
      margin: 10px 0;
      font-weight: normal;
    }
  }
}

