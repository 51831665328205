@import '../shared/settings/vars';

.page {
  .latch-card-content {
    padding: 0;
    min-height: 0;
  }

  &.fixed {
    .latch-card-content {
      overflow: auto;
    }

    .latch-card-footer {
      border-top: 1px solid rgba(0, 0, 0, .05);
    }
  }
}

.latch-dialog {
  .latch-card-content {
    overflow-y: auto;
  }
}

@media (max-height: $height-sm) {
  .page {

    &,
    &.fixed {
      overflow: auto;
      height: auto;

      .latch-card-content {
        min-height: auto;
        overflow: unset;
      }
    }
  }
}
