@import '../shared/modules/buttons';

.button-primary {
  // Override styles in shared button styles.
  // This is because of differences between the set / reset password design spec and the design language
  // currently used in Manager Web. Eventually these design specs will probably converge and we can have
  // just one definition of our styles without these overrides - until then, start with the MW default
  // and then override what we need to.
  background-color: #000;

  padding-top: 20px;
  padding-bottom: 20px;

  // Our button is taller than the default so we also have to increase our radius.
  border-radius: 30px;

  width: initial;
  min-width: 85%;
  margin: auto;

  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    opacity: .25;
    color: #fff;
  }
}
