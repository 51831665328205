// defaults.scss
//
// Sensible defaults for standard HTML elements.

@import '../shared/modules/defaults';

body {
  font-family: $fonts-paragraph;
  font-size: 16px;
  line-height: normal;
  color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fonts-paragraph;
  text-transform: none;
  letter-spacing: 0;
  margin: 0;
}

a {
  display: inline;
  color: $color-blue;
  text-decoration: none;
}
