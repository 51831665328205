// forms.scss
//
// Default form styles and overrides for v3 design patterns.
//
// This defines the form style used within MW, and the ID app, Keycard app, and forgot password page define slight
// variations on this style. At some point we should resolve which of those variations is the intended style and
// apply across all (MW, ID, Keycard, forgot password).
//
// Variations:
// * Within MW, .field-list .cell is used to define gray lines below each input box, while the ID app, Keycard app
//   and forgot password all have the line built in to the form-control itself. The MW style usually places a label
//   above each form-control, while the others all have only placeholder text without any label.
// * The ID app and forgot password use the .large-input class, defined below, to display larger text.
// * The Keycard app defines larger font-sizes within each component.
// * The Keycard app uses black lines, while the ID app and forgot password use gray lines.
// * The Keycard app and forgot password highlight the currently selected control by changing the line under it to
//   blue, while the ID app and MW do not.
// * The ID app and keycard app use form-control-feedback, while MW uses help-block. These classes serve very similar
//   functions.

.form-control {
  font-family: $fonts-paragraph;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  padding: 6px 8px;
  border: 0;
  width: 100%;
  outline: none;

  &.large-input {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .5px;
    padding: 14px 2px;
  }

  &.search {
    background-color: rgba(0, 0, 0, .02);
    border-radius: 4px;
    font-size: 12px;
    color: #000;

    &::placeholder {
      color: rgba(0, 0, 0, .5);
    }
  }

  &:focus {
    outline: none;
  }

  &[disabled] {
    opacity: .5;
  }
}

.form-control::placeholder {
  color: $color-gray-5;
}

.form-control-feedback {
  font-size: 12px;
  min-height: 16px;
}

.has-error {
  color: $color-danger;

  .help-block {
    color: $color-danger;
  }

  .form-control {
    border-color: $color-danger;
    border-top-color: $color-gray-4;
  }

  .form-control-feedback {
    color: $color-danger;
  }
}

select {
  width: 100%;
  height: 36px;
  background: none;
  border: 1px solid #e1e1e1;
}

.help-block {
  margin: 4px 0 0;
  font-size: 14px;
  line-height: 18px;
}

.message {
  font-size: 16px;
  line-height: 1.4;
}

.input-group {
  position: relative;

  .form-control {
    padding-right: 30px;
  }
}

.input-group-addon {
  position: absolute;
  top: 0;
  right: .5em;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

textarea {
  width: 100%;
  max-width: 100%;
  resize: none;
}
  